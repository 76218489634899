.background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}

.row {
    display: flex;
    width: 200%; /* Two full sets of images for seamless looping */
    height: 33.33%; /* Three equal rows */
    animation: moveLeft 60s linear infinite; /* Slow, continuous animation */
    filter: blur(8px); /* Apply a blur effect */
}

.row-1 {
    width: 500% !important;
}

.row-reverse {
    animation: moveRight 60s linear infinite; /* Slow, continuous animation */
    filter: blur(8px); /* Apply a blur effect */
}

.background-image {
    width: 10%; /* Adjust the size of each image to fit in the row */
    height: 100%;
    object-fit: cover;
    opacity: 0.7; /* Slightly reduce opacity for a softer look */
}

@keyframes moveLeft {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%); /* Move left by half the width of the container */
    }
}

@keyframes moveRight {
    0% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(0);
    }
}
